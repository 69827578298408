<template>
  <div>
    <v-row>
      <v-col cols="12" md="3" sm="4">
        <v-select
          v-model="formSelectedId"
          :items="modulesToSelect"
          item-text="description"
          item-value="id"
          color="dark_primary"
          placeholder="Seleccionar formulario"
          label="Formulario"
          no-data-text="Sin formularios..."
          outlined
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" v-show="display === 'read'">
        <div class="d-sm-flex justify-space-between align-center">
          <div class="d-sm-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  title="Imprimir"
                  class="mr-3"
                  @click="showPrintDialog"
                >
                  <v-icon size="20px">mdi-printer</v-icon>
                </v-btn>
              </template>
              <span class="caption">Imprimir</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  
                  icon
                  class="mr-3"
                  :title="
                    datesOrder === 'ascending' ? 'Ascendiente' : 'Descendiente'
                  "
                  @click="
                    datesOrder =
                      datesOrder === 'ascending' ? 'descending' : 'ascending'
                  "
                >
                  <v-icon size="20px">{{
                    datesOrder === "ascending"
                      ? "mdi-sort-calendar-descending"
                      : "mdi-sort-calendar-ascending"
                  }}</v-icon>
                </v-btn>
              </template>
              <span class="caption">{{
                datesOrder === "ascending" ? "Ascendiente" : "Descendiente"
              }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  
                  icon
                  class="mr-3"
                  title="Mostrar en dialog"
                  @click="showMedicalItemsByDate(datesWithItems[0].text)"
                >
                  <v-icon size="20px">mdi-feature-search</v-icon>
                </v-btn>
              </template>
              <span class="caption">Mostrar en dialog</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="doctorForms.length === 0">
      <v-col cols="12" class="text-center pasive--text mt-10">
        <p>
          Debes crear al menos un
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="gotTo('/formularios')"
            >formulario de historial clínico</span
          >
          para registrar información de este paciente...
        </p>
      </v-col>
    </v-row>
    <div v-else>
      <!-- read -->
      <div v-show="display === 'read'">
        <v-row>
          <v-col cols="12">
            <div id="print-content">
              <InfoItemListByDate
                ref="InfoItemListByDateRef"
                :modules="modules"
                :patient="patient"
                :allModules="allModules"
                :formatInfoItemTitle="getSectionSingleTitle"
                :datesWithItems="datesWithItems"
                :medicalRegisterDates="medicalRegisterDates"
                @move-tab="(value) => (tab = value)"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- add -->
      <div v-show="display === 'add'">
        <v-row>
          <v-col>
            <FormModules
              ref="medicalForm"
              :modules="modules"
              :patient="patient"
              :getSectionTitle="getSectionTitle"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormModules from "@/components/MedicalHistory/FormModules.vue";
import InfoItemListByDate from "../MedicalHistory/InfoItemListByDate.vue";
import moment from "moment";

export default {
  name: "PatientHistory",
  props: ["patient", "loading", "display", "medicalRegisterDates"],
  components: {
    FormModules,
    InfoItemListByDate,
  },
  data() {
    return {
      formSelectedId: null,
      modules: [],
      datesOrder: "descending",
      filterType: "Todas las fechas",
      output: null,
      filter: "",
      printDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "doctorForms",
      "doctorFormModules",
      "subForms",
      "medicalHistory",
    ]),
    modulesToSelect() {
      const forms = this.doctorForms;
      forms.unshift({
        id: -1,
        description: "Todos",
      });

      return forms;
    },
    allModules() {
      let temp = [];
      for (let module of this.subForms) {
        temp.push(module);
      }

      for (let module of this.doctorFormModules) {
        const customModule = this.getModuleTemplate(module);
        temp.push(customModule);
      }

      return temp;
    },
    datesWithItems() {
      let datesWithItems = [];

      // loop current form selected
      for (let subForm of this.modules) {
        for (let tab of subForm.tabs) {
          if (this.medicalHistory[tab.key]) {
            let items = this.medicalHistory[tab.key];
            for (let item of items) {
              const date = moment(item.date);

              const text = date.format("DD/MM/YYYY");
              const dateExist = datesWithItems.some((e) => e.text === text);

              if (!dateExist) {
                datesWithItems.push({
                  date,
                  text,
                });
              }
            }
          }
        }
      }

      // sort dates (descending or ascending)
      const datesWithItemsSorted = datesWithItems.sort((a, b) =>
        this.datesOrder === "descending"
          ? b.date.unix() - a.date.unix()
          : a.date.unix() - b.date.unix()
      );

      return datesWithItemsSorted;
    },
  },
  methods: {
    gotTo(route) {
      this.$router.push(route);
    },
    showPrintDialog() {
      this.$refs.InfoItemListByDateRef.showPrintDialog();
    },
    showMedicalItemsByDate(date) {
      const index = this.medicalRegisterDates.findIndex(
        (value) => value === date
      );

      console.log("index", index);
      console.log("this.medicalRegisterDates", this.medicalRegisterDates);
      console.log("this.date", date);

      this.$refs.InfoItemListByDateRef.currentIndexDate = index || 0;
      this.$refs.InfoItemListByDateRef.showItemsByDate = true;
    },
    getSectionTitle(formModule, tab) {
      if (formModule.tabs.length > 1) {
        return `${tab.description} (${formModule.description})`;
      }

      return formModule.description;
    },
    getSectionSingleTitle(formModule, tab) {
      if (formModule.tabs.length > 1 && tab.single !== formModule.single) {
        return `${tab.single} (${formModule.single})`;
      }

      return tab.single;
    },
    getModuleTemplate(module) {
      const moduleCode = module.id.toString();
      return {
        code: moduleCode,
        description: module.description,
        icon: "",
        color: "secondary",
        tabs: [
          {
            text: module.description,
            description: module.description,
            single: module.description,
            editorTemplate: module.content,
            icon: "",
            value: module.description,
            key: moduleCode,
            componentNames: {
              form: "patient-general-form",
              record: "patient-general-info-item",
              print: "patient-general-print",
            },
            api: {
              delete: "/patientGeneral/",
            },
          },
        ],
      };
    },
    handleFormSelected(id) {
      this.modules = [];

      if (id === -1) {
        this.modules = this.allModules;
        return true;
      }

      // get form to use
      const formToUse = this.doctorForms.filter((form) => form.id === id)[0];
      if (!formToUse) {
        return null;
      }

      // order form modules by position
      formToUse.formDetails = formToUse.formDetails.sort(
        (a, b) => parseFloat(a.position) - parseFloat(b.position)
      );

      // find in subforms array and add into modules
      for (let formDetail of formToUse.formDetails) {
        const module = this.subForms.filter(
          (form) => form.description === formDetail.description
        )[0];

        if (module) {
          this.modules.push(module);
        }

        const customModule = this.doctorFormModules.filter(
          (m) => m.id.toString() === formDetail.code
        )[0];

        if (customModule) {
          const module = this.getModuleTemplate(customModule);
          this.modules.push(module);
        }
      }
    },
  },
  watch: {
    formSelectedId: function (value) {
      this.handleFormSelected(value);
    },
  },
  created() {
    this.formSelectedId = -1;
  },
};
</script>
