<template>
  <div class="px-md-3">
    <div
      v-if="datesWithItems.length === 0"
      class="my-10 text-center d-flex justify-center"
    >
      <div>
        <div class="text-center grey--text">
          <v-icon color="grey" large>mdi-information</v-icon>
          <div class="mt-5">Paciente sin registros...</div>
        </div>
      </div>
    </div>

    <v-dialog v-model="showTemplateList">
      <v-card class="pa-5">
        <div class="text-right">
          <v-icon @click="showTemplateList = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title :title="'Seleccionar plantilla'" class="mb-8" />
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="searchTemplateInput"
              label="Buscar"
              append-icon="mdi-magnify"
              type="text"
              clearable
              dense
              outlined
              autofocus
            ></v-text-field>
          </v-col>
        </v-row>
        <TemplateList
          :searchInput="searchTemplateInput"
          :templates="doctorTemplates"
          :hideActions="true"
          :hover="true"
          @item-click="handleTemplateClick"
        />
      </v-card>
    </v-dialog>

    <PrintItems
      ref="PrintItemsRef"
      @hide-empty-values="hanldeChangeHideEmptyValues"
      :patient="patient"
    >
      <template v-slot:content>
        <div v-for="(date, indexDate) in datesWithItems" :key="indexDate">
          <div v-for="(formModule, indexModule) in modules" :key="indexModule">
            <div v-for="(tab, indexTab) in formModule.tabs" :key="indexTab">
              <PrintItemsList
                :date="date"
                :formModule="formModule"
                :tab="tab"
                :formatInfoItemTitle="formatInfoItemTitle"
                :formatDate="formatDate"
                :patient="patient"
                :items="getItemsToPrint(date.text, tab.key)"
                :hideEmptyValues="hideEmptyValues"
              />
            </div>
          </div>
        </div>
      </template>
    </PrintItems>

    <v-dialog
      v-model="showItemsByDate"
      @keydown.esc="showItemsByDate = false"
      persistent
    >
      <v-card class="pa-5" style="height: 100%; width: 100%">
        <div class="text-right">
          <v-icon @click="showItemsByDate = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <div
          :class="`mt-5 font-weight-medium secondary--text`"
          style="width: 100%"
        >
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%"
          >
            <div>
              <v-icon class="mb-2" color="secondary"
                >mdi-calendar-week-begin</v-icon
              >
              {{ formatDateToLetters(medicalRegisterDates[currentIndexDate]) }}
              <span class="px-1"> | </span> {{ patient.firstName }}
              {{ patient.lastName }}
            </div>
            <div class="d-flex justify-end">
              <v-btn
                @click="currentIndexDate = currentIndexDate - 1"
                :disabled="!medicalRegisterDates[currentIndexDate - 1]"
                icon
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-btn
                @click="currentIndexDate = currentIndexDate + 1"
                :disabled="!medicalRegisterDates[currentIndexDate + 1]"
                icon
              >
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
            </div>
          </div>
          <v-divider class="secondary"></v-divider>
        </div>
        <div style="overflow-y: auto; height: 74vh; width: 100%">
          <div
            v-for="(formModule, indexModule) in allModules"
            :key="indexModule"
          >
            <div v-for="(tab, indexTab) in formModule.tabs" :key="indexTab">
              <div
                v-for="item in getItemsByDate(
                  medicalRegisterDates[currentIndexDate],
                  tab.key
                )"
                :key="item.id"
                class="my-2 px-2"
              >
                <v-col cols="12">
                  <v-card class="pa-5" style="border-radius: 8px" elevation="6">
                    <div class="d-md-flex justify-space-between align-center">
                      <h4 class="secondary--text">
                        <v-icon
                          :color="color || 'secondary'"
                          class="rounded-pill mb-1"
                          size="sm"
                          >{{ tab.icon }}</v-icon
                        >
                        {{ formatInfoItemTitle(formModule, tab) }}
                      </h4>

                      <div class="caption secondary--text mt-md-0 mt-3">
                        <span>
                          {{
                            timeAgoFromDate(
                              formatDateISO8601(
                                medicalRegisterDates[currentIndexDate]
                              )
                            )
                          }}
                        </span>
                        <span class="ml-1">
                          {{ medicalRegisterDates[currentIndexDate] }}
                        </span>
                      </div>
                    </div>
                    <v-divider class="my-5"></v-divider>
                    <component
                      :is="tab.componentNames.record"
                      :item="item"
                      :formatDate="formatDate"
                      @click-edit="
                        (item) =>
                          showFormToEdit(item, {
                            form: tab.componentNames.form,
                            key: tab.key,
                            deleteEndpoint: tab.api.delete,
                          })
                      "
                      @click-delete="
                        (item) =>
                          showDeleteWarning(item, {
                            form: tab.componentNames.form,
                            key: tab.key,
                            deleteEndpoint: tab.api.delete,
                          })
                      "
                    ></component>
                  </v-card>
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <div v-for="(date, indexDate) in datesWithItems" :key="indexDate">
      <div
        :class="`${
          indexDate === 0 ? '' : ' pt-16 '
        } mt-5 font-weight-medium secondary--text`"
      >
        <v-icon class="mb-2" color="secondary">mdi-calendar-week-begin</v-icon>
        {{ formatDateToLetters(date.text) }}
        <v-divider class="secondary"></v-divider>
      </div>
      <div v-for="(formModule, indexModule) in modules" :key="indexModule">
        <div v-for="(tab, indexTab) in formModule.tabs" :key="indexTab">
          <v-row
            v-for="item in getItemsByDate(date.text, tab.key)"
            :key="item.id"
            align="center"
            class="my-2 px-2 px-md-5"
          >
            <v-col cols="12">
              <v-card class="pa-5" style="border-radius: 8px" elevation="6">
                <div class="d-md-flex justify-space-between align-center">
                  <h4 class="secondary--text">
                    <v-icon
                      :color="color || 'secondary'"
                      class="rounded-pill mb-1"
                      size="sm"
                      >{{ tab.icon }}</v-icon
                    >
                    {{ formatInfoItemTitle(formModule, tab) }}
                  </h4>

                  <div class="caption secondary--text mt-md-0 mt-3">
                    <span>
                      {{ timeAgoFromDate(date.date) }}
                    </span>
                    <span class="ml-1"> ({{ date.text }}) </span>
                  </div>
                </div>
                <v-divider class="my-5"></v-divider>
                <component
                  :is="tab.componentNames.record"
                  :item="item"
                  :formatDate="formatDate"
                  @click-edit="
                    (item) =>
                      showFormToEdit(item, {
                        form: tab.componentNames.form,
                        key: tab.key,
                        deleteEndpoint: tab.api.delete,
                      })
                  "
                  @click-delete="
                    (item) =>
                      showDeleteWarning(item, {
                        form: tab.componentNames.form,
                        key: tab.key,
                        deleteEndpoint: tab.api.delete,
                      })
                  "
                ></component>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="showEditForm"
      @keydown.esc="showEditForm = false"
      persistent
    >
      <v-card class="pa-5" v-if="form && key && patient && item">
        <div class="text-right">
          <v-icon @click="showEditForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <Title :title="'Modificar datos'" class="mb-8" />
        <component
          :ref="key"
          :is="form"
          :patient="patient"
          :item="item"
          :field="key"
          :action="action"
          @updated="(item) => handleUpdated({ item })"
          @show-template-list="showTemplateList = true"
        ></component>
      </v-card>
    </v-dialog>

    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteItem"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";
import TimeAgo from "javascript-time-ago";
import PrintItems from "./PrintItems.vue";
import WarningDialog from "../WarningDialog.vue";
import FamilyBackgroundInfoItem from "./InfoItems/FamilyBackgroundInfoItem.vue";
import PhysicalActivityInfoItem from "./InfoItems/PhysicalActivityInfoItem.vue";
import AllergyInfoItem from "./InfoItems/AllergyInfoItem.vue";
import CardioAnalysisInfoItem from "./InfoItems/CardioAnalysisInfoItem.vue";
import DiagnosticHistoryInfoItem from "./InfoItems/DiagnosticHistoryInfoItem.vue";
import DiagnosticInfoItem from "./InfoItems/DiagnosticInfoItem.vue";
import GynecologyInfoItem from "./InfoItems/GynecologyInfoItem.vue";
import LabResultInfoItem from "./InfoItems/LabResultInfoItem.vue";
import MedicalEvaluationInfoItem from "./InfoItems/MedicalEvaluationInfoItem.vue";
import MedicationInfoItem from "./InfoItems/MedicationInfoItem.vue";
import SurgeryInfoItem from "./InfoItems/SurgeryInfoItem.vue";
import ToxicHabitInfoItem from "./InfoItems/ToxicHabitInfoItem.vue";
import PatientGeneralInfoItem from "./InfoItems/PatientGeneralInfoItem.vue";
import FormModule from "./FormModule.vue";
import TemplateList from "../Template/TemplateList.vue";
import PrintItemsList from "./PrintItemsList.vue";
import FamilyBackgroundForm from "./Forms/FamilyBackgroundForm.vue";
import PhysicalActivityForm from "./Forms/PhysicalActivityForm.vue";
import AllergyForm from "./Forms/AllergyForm.vue";
import CardioAnalysisForm from "./Forms/CardioAnalysisForm.vue";
import DiagnosticHistoryForm from "./Forms/DiagnosticHistoryForm.vue";
import DiagnosticForm from "./Forms/DiagnosticForm.vue";
import GynecologyForm from "./Forms/GynecologyForm.vue";
import LabResultForm from "./Forms/LabResultForm.vue";
import MedicalEvaluationForm from "./Forms/MedicalEvaluationForm.vue";
import MedicationForm from "./Forms/MedicationForm.vue";
import SurgeryForm from "./Forms/SurgeryForm.vue";
import ToxicHabitForm from "./Forms/ToxicHabitForm.vue";
import PatientGeneralForm from "./Forms/PatientGeneralForm.vue";
import Title from "../Title.vue";

export default {
  name: "InfoItemListByDate",
  components: {
    Title,
    FamilyBackgroundForm,
    PatientGeneralForm,
    PhysicalActivityForm,
    ToxicHabitForm,
    SurgeryForm,
    MedicationForm,
    CardioAnalysisForm,
    AllergyForm,
    MedicalEvaluationForm,
    DiagnosticHistoryForm,
    LabResultForm,
    GynecologyForm,
    DiagnosticForm,
    FormModule,
    FamilyBackgroundInfoItem,
    PhysicalActivityInfoItem,
    WarningDialog,
    AllergyInfoItem,
    CardioAnalysisInfoItem,
    DiagnosticHistoryInfoItem,
    DiagnosticInfoItem,
    GynecologyInfoItem,
    LabResultInfoItem,
    MedicalEvaluationInfoItem,
    MedicationInfoItem,
    SurgeryInfoItem,
    ToxicHabitInfoItem,
    PatientGeneralInfoItem,
    PrintItems,
    TemplateList,
    PrintItemsList,
  },
  props: [
    "modules",
    "formatInfoItemTitle",
    "patient",
    "datesWithItems",
    "allModules",
    "medicalRegisterDates",
  ],
  data() {
    return {
      model: 0,
      infoItemName: "",
      items: [],
      title: "",
      icon: "",
      color: "",
      form: "",
      key: "",
      deleteEndpoint: "",
      item: null,
      showEditForm: false,
      deleting: false,
      showTemplateList: false,
      showItemsByDate: false,
      hideEmptyValues: false,
      searchTemplateInput: "",
      currentDate: "",
      currentIndexDate: 0,
    };
  },
  computed: {
    ...mapGetters(["medicalHistory", "doctorTemplates"]),
  },
  methods: {
    ...mapMutations([
      "updateFromMedicalHistory",
      "removeFromMedicalHistory",
      "setAlert",
    ]),
    // template selected
    handleTemplateClick(template) {
      this.$refs[this.key].setTemplateContent(template.content);
      this.showTemplateList = false;
    },
    getItemsByDate(date, key) {
      if (!this.medicalHistory[key]) {
        return [];
      }

      return this.medicalHistory[key].filter((item) => {
        if (date === moment(item.date).format("DD/MM/YYYY")) {
          return item;
        }
      });
    },

    hanldeChangeHideEmptyValues(val) {
      this.hideEmptyValues = val;
    },

    getItemsToPrint(date, key) {
      if (!this.medicalHistory[key]) {
        return [];
      }

      return this.medicalHistory[key].filter((item) => {
        if (date === moment(item.date).format("DD/MM/YYYY")) {
          this.$set(item, "showPrint", true);
          return item;
        }
      });
    },

    formatDateISO8601(date) {
      return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    toggleShowPrint(item) {
      this.$set(item, "showPrint", !item.showPrint);
      this.$forceUpdate();
    },

    showPrintDialog() {
      this.$refs.PrintItemsRef.show = true;
    },

    init({
      items,
      componentName,
      title,
      icon,
      color,
      formComponentName,
      key,
      deleteEndpoint,
    }) {
      this.items = items;
      this.infoItemName = componentName;
      this.form = formComponentName;
      this.deleteEndpoint = deleteEndpoint;
      this.title = title;
      this.key = key;
      this.icon = icon;
      this.color = color;
    },
    showFormToEdit(item, { form, key, deleteEndpoint }) {
      this.showEditForm = true;
      this.form = form;
      this.deleteEndpoint = deleteEndpoint;
      this.key = key;
      this.item = item;
    },
    handleUpdated({ item }) {
      this.updateFromMedicalHistory({ item, field: this.key });
      this.showEditForm = false;
    },
    showDeleteWarning(item, { form, key, deleteEndpoint }) {
      this.form = form;
      this.deleteEndpoint = deleteEndpoint;
      this.key = key;

      this.item = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar el registro?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteItem() {
      try {
        this.deleting = true;
        const res = await axios.delete(
          `/api${this.deleteEndpoint}${this.item.id}`
        );

        // show message response from api
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        // remove from state
        this.items = this.items.filter((i) => i.id !== this.item.id);

        // remove from global state
        this.removeFromMedicalHistory({
          item: this.item,
          field: this.key,
        });

        this.$refs.WarningDialogRef.show = false;
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateToLetters(date) {
      date = date ? moment(date, "DD/MM/YYYY").locale("es").format("LLLL") : "";
      return date !== "" ? date.substring(0, date.length - 4) : date;
    },
    timeAgoFromDate(date) {
      const itemDate = moment(date, "YYYY-MM-DD");
      const currentDate = moment();

      if (itemDate.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD")) {
        return "Hoy";
      }

      const miliseconds = itemDate.valueOf();

      const timeAgo = new TimeAgo("es-ES");
      return timeAgo.format(miliseconds);
    },
  },
};
</script>
